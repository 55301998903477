<template>
  <div class="loginSection">
    <loader v-show="isLoading"></loader>
    <div class="layoutBorder"  v-show="!isLoading">
      <div class="layoutHeader">
        <h4>Вход в личный кабинет клиента ФИНМОЛЛ</h4>
      </div>
      <div class="layoutBody">
        <div class="inputBox">

          <input type="tel" name="login" id="login"
                 v-model.trim="DlCode"
                 v-on:focus="responseError = ''"
                 v-on:blur="checkLogin"
          >
          <label for="login">Логин(Номер договора)</label>
          <div v-show="errorlogin.length > 0" v-html="errorlogin" class="errMsg"></div>
        </div>

        <div class="inputBox">
          <label for="pass">Пароль</label>
          <input type="password" name="password" id="pass"
                 v-model.trim="password"
                 v-on:focus="responseError = ''"
                 v-on:blur="checkPassword"
          >
          <div v-show="errorpassword.length > 0" v-html="errorpassword" class="errMsg"></div>
        </div>

        <div v-show="responseError.length > 0" v-html="responseError" class="errMsg"></div>
      </div>
      <div class="layoutFooter">
        <div id="navLoginWrap"   v-show="!isLoading">
          <router-link :class="'linkColor'" to="/login/nsb/recovery">Забыли пароль?</router-link><br/>
          <router-link :class="'linkColor'" to="/login/nsb/signin">Регистрация</router-link>
        </div>
        <button v-on:click="sendAction" class="submitButton" v-show="!isLoading">Войти</button>
      </div>

    </div>

  </div>
</template>

<script>
  import {mapState} from 'vuex';

  export default {
    name: "LoginForm",
    data() {
      return {
        password: '',
        errorlogin: '',
        errorpassword: '',
        responseError: '',
        isLoading:false,
      }
    },
    methods: {
      checkPassword() {
        if (this.password.length < 6) {
          this.errorpassword = 'Некорреткный пароль';
          return false;
        } else {
          this.errorpassword = '';
          return true;
        }
      },
      checkLogin() {
        if (this.$bikeFunction.correctDlCode(this.DlCode)) {
          this.errorlogin = '';
          return true;
        } else {
          this.errorlogin = 'Некорректный логин';
          return false;
        }
      },
      checkRequriedFields() {
        let countErr = 0;
        if (!this.checkLogin()) {
          countErr += 1;
        }
        if (!this.checkPassword()) {
          countErr += 1;
        }
        return countErr === 0;
      },
      tryLogin(){
        this.isLoading = true;
        this.$external.universalRequest({
          externalType: 'zita',
          action: 'loginNsb',
          Demandid: this.DlCode,
          PasswordHash: this.password
        })
        .then((res) => {
          this.$store.commit('setUserID', res.UserID);
          this.$store.commit('setSessionKey', res.SessionKey);
          this.$router.push('/');
        })
        .catch((res) => {
          if ( res.errorCode > 0 || res.errorCode !== undefined ) {
            alert('Ошибка' + ' ' + res.errorCode + ': ' + res.errorMsg)
          }
          try {
            this.responseError = res.errorMsg;
          } catch (e) {
            this.responseError = JSON.stringify(res);
          }
        }).finally(()=>{this.isLoading = false});
      },
      sendAction() {
        if (this.checkRequriedFields()) {
          this.tryLogin();
        }
      }
    },
    components:{
      loader:()=>import('@/components/other/loader'),
    },
    computed: {
      DlCode: {
        get() {
          return this.loginVuex;
        },
        set(value) {
          this.$store.commit('setDlCode', value)
        }
      },
      ...mapState({
        loginVuex: state => state.auth.DlCode,
        passwordVuex: state => state.password,
      }),
    },
    mounted(){
      let DlCodeIncome = parseInt(this.$route.query.DlCode, 10);
      this.$store.dispatch('setDlCode', DlCodeIncome);
      this.isLoading = false;
      this.$myApi.checkVersion();
    }
  }
</script>

<style scoped>

</style>
